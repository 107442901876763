import React from 'react'
import { Typography } from '~/components/typography'
import { useStyles } from '~/components/circular-percentage/circular-percentage.style'
import classnames from 'classnames'
import { Box, CircularProgress } from '@material-ui/core'

const CircularProgressWithLabel = ({ size, thickness, value, showPercentage, customText, customPercentageColor }) => {
  const {
    label,
    top,
    bottom,
    circle,
    root,
    // greenPercent,
    orangePercent,
    // redPercent
  } = useStyles()

  // const color = useMemo(() => {
  // 	return {
  // 		[greenPercent]: value >= 80 && value <= 100,
  // 		[orangePercent]: value >= 50 && value < 80,
  // 		[redPercent]: value < 50,
  // 	}
  // }, [value, redPercent, orangePercent, greenPercent])
  return (
    <Box position="relative" display="inline-flex" className={root} style={{ width: size, height: size }}>
      <CircularProgress
        className={classnames(bottom, customPercentageColor ? customPercentageColor : orangePercent)}
        size={size}
        value={value}
        thickness={thickness}
        variant="static"
      />
      <CircularProgress
        variant="static"
        value={100}
        className={top}
        size={size}
        thickness={thickness}
        classes={{
          circle,
        }}
      />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center"
           justifyContent="center">
        <Typography
          // className={classnames(label, {
          // 	[greenPercent]: value >= 80 && value <= 100,
          // 	[orangePercent]: value >= 50 && value < 80,
          // 	[redPercent]: value < 50,
          // })}
          className={classnames(label, orangePercent)}
          component="div"
        >{customText ? customText : (`${Math.round(value)}${showPercentage ? '%' : ''}`)}</Typography>
      </Box>
    </Box>
  )
}

export const CircularPercentage = ({
  value,
  size = 40,
  showPercentage = true,
  customText,
  customPercentageColor,
  thickness = 4
}) => {
  return <CircularProgressWithLabel
    size={size}
    value={value}
    showPercentage={showPercentage}
    customText={customText}
    customPercentageColor={customPercentageColor}
    thickness={thickness}
  />
}
