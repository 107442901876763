import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	top: {
		color: theme.colors.content.light,
		position: 'absolute',
		left: 0,
	},
	greenPercent: {
		color: theme.palette.status.success.medium,
	},
	bottom: {
		zIndex: 1,
	},
	orangePercent: {
		color: theme.palette.status.warning.medium,
	},
	redPercent: {
		color: theme.palette.status.error.medium,
	},
	label: {
		...theme.typography.footNote,
		fontWeight: 800,
	},
	circle: {
		strokeLinecap: 'round',
	},
}))
