import { action, computed, event, observable, persist, store } from '~/common/mobx.decorator'
import { appStore, authStore, routerStore } from '~/stores'
import {
	ActivationNavSvg,
	ChangePassSvg,
	ClipboardSvg,
	ContactUsSvg,
	CupSvg,
	FindFullSvg,
	FindSvg,
	HomeFullSvg,
	HomeSvg,
	MyJobsFullSvg,
	MyJobsSvg,
	PersonalCardSvg,
	PrivacySvg,
	SettingsSvg,
	TimecardNavFullSvg,
	TimecardNavSvg,
	UserPlusSvg,
} from '~/components/icons'
import { EVENTS, PATHS } from '~/common/constants'
import {
	apolloClient,
	APPLY_JOB_MUTATION,
	GET_COMPANY_INFO_QUERY,
	GET_WORKER_AVATAR,
	GET_WORKER_BASIC_INFO_QUERY,
	GET_WORKER_DETAIL_QUERY,
	GET_COMPANY_PUBLIC_INFO_QUERY,
} from '~/common/apollo'
import { eventBus } from 'mobx-event-bus2'

import { EVENT_CLICK_ID } from '~/common/tracking/event-click.constant'
import { getStateValues } from '~/common/helpers/mapping.helper'

export const PATH_NAME = window.location.path

const FEATURE_TYPE = {
	activated: 'activated',
	nonActivated: 'nonActivated',
	public: 'public',
	common: 'common',
}

@store()
class CareStore {
	@observable showProfileSidebar
	@observable showOnboardReminderCard = true
	@observable customHeader = {}
	@observable worker = {}
	@observable workerBasicInfo = {}
	@observable companyInfo = {}
	@observable companyConfig = {}
	@observable workerConfigInfo = {}
	@observable sclProvider = {}
	@persist @observable workerAvatar = null
	@observable workerAvatarId = null
	@observable timezone = null
	@observable chatIntegrationSetting = null

	@computed
	get privacyPolicyUrl() {
		return appStore.config?.companyConfigs?.privacyPolicyUrl
	}

	@computed
	get termsOfUseUrl() {
		return appStore.config?.companyConfigs?.termUrl
	}

	@computed
	get facebookUrl() {
		return this.companyConfig?.facebookUrl
	}

	@computed
	get youtubeUrl() {
		return this.companyConfig?.youtubeUrl
	}

	@computed
	get instagramUrl() {
		return this.companyConfig?.instagramUrl
	}

	@computed
	get enableHotJobs() {
		if (this.companyConfig) {
			const hotJobsFeature = this.companyConfig?.featureFlags?.find((feature) => feature.feature === 'hotjobs')
			if (hotJobsFeature && hotJobsFeature.enabled) {
				return true
			}
		}
		return false
	}

	@computed
	get shouldConfirmCreateUnaAccount() {
		return (
			(this.workerBasicInfo?.customField1 === 'New Lead' ||
				this.workerBasicInfo?.customField2 === 'Prospect' ||
				(this.workerBasicInfo?.customField2 === null && this.workerBasicInfo?.customField1 === null)) &&
			!this.sclProvider
		)
	}

	@computed
	get symplrStatus() {
		return this.workerBasicInfo?.customField2
	}

	@computed
	get symplrStatusActive() {
		return this.workerBasicInfo?.customField2 === 'Active'
	}
	@computed
	get bullhornStatus() {
		return this.workerBasicInfo?.customField1
	}

	@computed
	get bullhornStatusActive() {
		return this.workerBasicInfo?.customField1 === 'Active'
	}

	@computed
	get verifiedPreferredLocations() {
		return getStateValues(this.workerBasicInfo?.verifiedPreferredLocations) || []
	}

	@computed
	get isWorkerActive() {
		return this.workerBasicInfo?.isActiveStatus === true
	}

	@computed
	get twitterUrl() {
		return this.companyConfig?.twitterUrl
	}

	@computed
	get linkedInUrl() {
		return this.companyConfig?.linkedInUrl
	}

	@computed
	get noSms() {
		return this.worker.noSms
	}

	@computed
	get enableSmsSender() {
		return this.worker?.enableSmsSender
	}

	@computed
	get messagingServiceSender() {
		return this.companyConfig?.messagingServiceSender
	}

	@computed
	get privacyPolicyContent() {
		return this.companyConfig?.privacyPolicyContent
	}

	@computed
	get adpLink() {
		return 'https://online.adp.com/signin/v1/?APPID=WFNPortal&productId=80e309c3-7085-bae1-e053-3505430b5495&returnURL=https://workforcenow.adp.com/&callingAppId=WFN'
	}

	@computed
	get features() {
		const publicMenu = this.getFeature(FEATURE_TYPE.public)
		const activatedMenu = this.getFeature(FEATURE_TYPE.activated)
		const nonActivatedMenu = this.getFeature(FEATURE_TYPE.nonActivated)

		return !authStore.id ? publicMenu : authStore.activated && authStore.id ? activatedMenu : nonActivatedMenu
	}

	@computed
	get allFeatures() {
		const common = this.getFeature(FEATURE_TYPE.common)
		const allFeature = [...this.features, ...common]

		return allFeature?.find((feature) => feature.path === routerStore.basePath)
	}

	@computed
	get activeFeature() {
		return this.features?.find((feature) => feature.path === window.location.pathname)
	}
	@action
	getActiveFeature = () => {
		return this.features?.find((feature) => feature.path === routerStore.basePath)
	}

	@computed
	get code() {
		return this.getActiveFeature()?.code
	}

	@computed
	get name() {
		return this.getActiveFeature()?.name || ''
	}

	@computed
	get useBanner() {
		return this.getActiveFeature()?.useBanner
	}

	@computed
	get showOnboardReminder() {
		return this.getActiveFeature()?.showOnboardReminder && this.showOnboardReminderCard
	}

	@computed
	get getReferalRewardContent() {
		return this.companyConfig.referalRewardContent
	}

	@computed
	get sidebarMenu() {
		return this.features?.filter((feature) => feature.showSideBar)
	}

	@computed
	get headerMenu() {
		return this.features?.filter((feature) => feature.showHeader)
	}

	@computed
	get dropDownMenu() {
		return this.features?.filter((feature) => feature.showDropdown)
	}

	@computed
	get appbarMenu() {
		return this.features?.filter((feature) => feature.showAppBar)
	}

	@computed
	get activeStatus() {
		return this.bullhornStatusActive || this.symplrStatusActive
	}

	@action
	createJobApplicant = async (jobId) => {
		await apolloClient
			.mutate({
				mutation: APPLY_JOB_MUTATION,
				variables: { jobId },
			})
			.then(async (res) => {
				// this.fetchSlcProvider()
				return res
			})
	}

	@action
	fetchWorkerDetail = async () => {
		const { data } = await apolloClient.query({
			query: GET_WORKER_DETAIL_QUERY,
			variables: { id: authStore.id },
		})
		this.worker = data?.worker
	}
	fetchWorkerBasicInfo = async () => {
		const { data } = await apolloClient.query({
			query: GET_WORKER_BASIC_INFO_QUERY,
			variables: { id: authStore.id },
		})
		this.workerBasicInfo = data?.worker
		this.sclProvider = data?.worker?.sclProvider
		authStore.setWorkAuthorized(data?.worker?.workAuthorizedAt)
	}

	@computed
	get isWorkerHasAuthBadge() {
		const badges = this.workerBasicInfo?.workerBadges?.filter((item) => item.badgeType === 'verified_profile')
		return badges && badges?.length > 0
	}
	@action
	fetchWorkerAvatar = async () => {
		const { data } = await apolloClient.query({
			query: GET_WORKER_AVATAR,
		})
		this.workerAvatar = data?.worker?.avatar?.file?.thumbnails?.s_100x100
		this.workerAvatarId = data?.worker?.avatar?.id ?? null
	}

	@computed
	get compamyInfo() {
		return this.company
	}

	@computed
	get companyName() {
		return this.compamyInfo?.name
	}

	@action
	fetchPublicPrivacyInfo = async () => {
		const { data } = await apolloClient.query({ query: GET_COMPANY_PUBLIC_INFO_QUERY, context: { clientName: 'public' } })
		this.companyConfig = {
			...this.companyConfig,
			privacyPolicyContent: data?.appInit?.tenantConfig?.privacyPolicyContent,
		}
		this.chatIntegrationSetting = data?.appInit?.chatIntegrationSetting
	}

	@action
	fetchCompanyInfo = async () => {
		const { data } = await apolloClient.query({ query: GET_COMPANY_INFO_QUERY })
		this.tenant = data?.appInit?.tenant?.timezone
		this.timezone = data?.appInit?.tenant?.timezone
		this.companyInfo = data?.appInit
		this.companyConfig = {
			instagramUrl: data?.appInit?.tenantConfig?.instagramUrl,
			facebookUrl: data?.appInit?.tenantConfig?.facebookUrl,
			linkedInUrl: data?.appInit?.tenantConfig?.linkedInUrl,
			youtubeUrl: data?.appInit?.tenantConfig?.youtubeUrl,
			privacyPolicyContent: data?.appInit?.tenantConfig?.privacyPolicyContent,
		}
	}

	@action
	fetchCompanyInfoPublic = async () => {
		const { data } = await apolloClient.query({ query: GET_COMPANY_PUBLIC_INFO_QUERY, context: { clientName: 'public' } })
		this.companyConfig = {
			instagramUrl: data?.appInit?.tenantConfig?.instagramUrl,
			facebookUrl: data?.appInit?.tenantConfig?.facebookUrl,
			linkedInUrl: data?.appInit?.tenantConfig?.linkedInUrl,
			youtubeUrl: data?.appInit?.tenantConfig?.youtubeUrl,
			privacyPolicyContent: data?.appInit?.tenantConfig?.privacyPolicyContent,
		}
	}

	getFeature = (type) => {
		const activatedMenu = [
			{
				code: 'notifications',
				name: 'NOTIFICATIONS',
				path: PATHS.common.notifications,
				icon: 'user',
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'home',
				name: 'HOME',
				path: PATHS.care.home,
				icon: HomeSvg,
				iconFilled: HomeFullSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				showHeader: true,
				useBanner: false,
				useLayout: true,
				eventId: EVENT_CLICK_ID.clickHomeNavBtn,
			},
			{
				code: 'job-results',
				name: 'SEARCH_JOBS',
				path: PATHS.care.search,
				icon: FindSvg,
				iconFilled: FindFullSvg,
				auth: true,
				showAppBar: true,
				eventId: EVENT_CLICK_ID.clickSearchJobsNavBtn,
				showSideBar: true,
				showHeader: true,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.filter,
				icon: FindSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'edit-filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.edit_filter,
				icon: FindSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'job-results',
				name: 'SEARCH_RESULTS',
				path: PATHS.care.search,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'my-jobs',
				name: 'MY_JOBS',
				path: PATHS.care.my_jobs,
				icon: MyJobsSvg,
				iconFilled: MyJobsFullSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				showHeader: true,
				isDisabled: false,
				useBanner: true,
				eventId: EVENT_CLICK_ID.clickMyJobsNavBtn,
				bannerUrl: PATHS.banners.third,
			},
			{
				code: 'timecards',
				name: 'TIMECARDS',
				path: PATHS.care.timecards,
				icon: TimecardNavSvg,
				iconFilled: TimecardNavFullSvg,
				auth: false,
				showAppBar: false,
				showSideBar: true,
				showHeader: true,
				isDisabled: false,
				useBanner: false,
				eventId: EVENT_CLICK_ID.clickTimecardsNavBtn,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'profile',
				name: 'PROFILE',
				path: PATHS.care.profile,
				icon: PersonalCardSvg,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'my-badges',
				name: 'MY_BADGES',
				path: PATHS.care.badges,
				icon: CupSvg,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'credentials',
				name: 'CREDENTIALS',
				path: PATHS.care.credentials,
				icon: ClipboardSvg,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'referrer',
				name: 'REFERRER',
				icon: UserPlusSvg,
				path: PATHS.care.referrer,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'contact-us',
				name: 'CONTACT_US',
				icon: ContactUsSvg,
				path: PATHS.care.contact_us,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				useBanner: false,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'settings',
				name: 'SETTINGS',
				path: PATHS.care.settings,
				eventId: EVENT_CLICK_ID.clickSettingsNavBtn,
				icon: SettingsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				showDropdown: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'change-password',
				name: 'CHANGE_PASSWORD',
				icon: ChangePassSvg,
				path: PATHS.common.change_password,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.third,
			},

			{
				code: 'update-phone',
				name: 'UPDATE_PHONE_NUMBER',
				icon: ChangePassSvg,
				path: PATHS.common.update_phone,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},

			{
				code: 'popular-cities',
				name: 'POPULAR_CITIES',
				path: PATHS.care.popular_cities,
				icon: MyJobsSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBreadcrumb: true,
			},
			{
				code: 'popular-cities-detail',
				name: 'POPULAR_CITY_JOBS',
				path: PATHS.care.popular_cities_detail,
				icon: MyJobsSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBreadcrumb: true,
			},
			{
				code: 'recommended-jobs',
				name: 'EXPLORE_MATCHES',
				path: PATHS.care.suggested_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
				useBreadcrumb: true,
			},
			{
				code: 'urgent-needs',
				name: 'HOT_JOBS',
				path: PATHS.care.urgent_needs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
				useBreadcrumb: true,
			},
			{
				code: 'trending-jobs',
				name: 'TRENDING_JOBS',
				path: PATHS.care.trending_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'job-alerts',
				name: 'NOTIFICATIONS',
				path: PATHS.care.job_alerts,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'last-viewed',
				name: 'LAST_VIEWED',
				path: PATHS.care.last_viewed,
				icon: MyJobsSvg,
				auth: false,
				useBreadcrumb: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'available-perdiem-shift',
				name: 'AVAILABLE_PERDIEM_SHIFT',
				useBreadcrumb: true,
				path: PATHS.care.available_perdiem_shift,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'bookmark',
				name: 'BOOKMARKED_JOBS',
				path: PATHS.care.bookmark,
				icon: MyJobsSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: true,
				bannerUrl: PATHS.banners.first,
				useBreadcrumb: true,
			},
			{
				code: 'skills-checklists',
				name: 'SKILLS_CHECKLISTS',
				path: PATHS.care.skills_checklists,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: true,
				bannerUrl: PATHS.banners.first,
				useBreadcrumb: true,
			},
			{
				code: 'authorization',
				name: 'AUTHORIZATION',
				path: PATHS.common.authorization,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useBanner: false,
			},
			{
				code: 'signin',
				name: 'SIGNIN',
				path: PATHS.common.signin,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useBanner: false,
			},

			{
				code: 'verify-credential',
				name: 'VERIFY_CREDENTIAL',
				path: PATHS.common.verify_credential,
				icon: 'privacy',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useLayout: false,
			},
			{
				code: 'email-unsubscribe',
				name: 'EMAIL_UNSUBSCRIBE',
				path: PATHS.care.email_unsubscribe,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'privacy-policy',
				name: 'PRIVACY_POLICY',
				path: PATHS.care.privacy_policy,
				icon: PrivacySvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				useBanner: false,
				useLayout: true,
			},
		].filter(Boolean)

		const nonActivatedMenu = [
			{
				code: 'notifications',
				name: 'NOTIFICATIONS',
				path: PATHS.common.notifications,
				icon: 'user',
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'home',
				name: 'HOME',
				path: PATHS.care.home,
				icon: HomeSvg,
				iconFilled: HomeFullSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				showHeader: true,
				useBanner: false,
				useLayout: true,
				eventId: EVENT_CLICK_ID.clickHomeNavBtn,
			},
			{
				code: 'skills-checklists',
				name: 'SKILLS_CHECKLISTS',
				path: PATHS.care.skills_checklists,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: true,
				bannerUrl: PATHS.banners.first,
				useBreadcrumb: true,
			},
			{
				code: 'completion_homepage',
				name: 'ACTIVATION',
				path: PATHS.care.activation,
				icon: PersonalCardSvg,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'my-badges',
				name: 'MY_BADGES',
				path: PATHS.care.badges,
				icon: CupSvg,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'credentials',
				name: 'CREDENTIALS',
				path: PATHS.care.credentials,
				icon: ClipboardSvg,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'job-alerts',
				name: 'NOTIFICATIONS',
				path: PATHS.care.job_alerts,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
			},

			{
				code: 'update-phone',
				name: 'UPDATE_PHONE_NUMBER',
				icon: ChangePassSvg,
				path: PATHS.common.update_phone,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},

			{
				code: 'job-results',
				name: 'SEARCH_JOBS',
				path: PATHS.care.search,
				icon: FindSvg,
				iconFilled: FindFullSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				showHeader: true,
				eventId: EVENT_CLICK_ID.clickSearchJobsNavBtn,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.filter,
				icon: FindSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'job-results',
				name: 'SEARCH_RESULTS',
				path: PATHS.care.search,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'my-jobs',
				name: 'MY_JOBS',
				path: PATHS.care.my_jobs,
				icon: MyJobsSvg,
				iconFilled: MyJobsFullSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				showHeader: true,
				isDisabled: false,
				useBanner: true,
				eventId: EVENT_CLICK_ID.clickMyJobsNavBtn,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'timecards',
				name: 'TIMECARDS',
				path: PATHS.care.timecards,
				icon: TimecardNavSvg,
				iconFilled: TimecardNavFullSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				showHeader: true,
				isDisabled: false,
				useBanner: false,
				eventId: EVENT_CLICK_ID.clickTimecardsNavBtn,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'completion_homepage',
				name: 'COMPLETE_YOUR_PROFILE',
				path: PATHS.care.activation,
				icon: ActivationNavSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: true,
				eventId: EVENT_CLICK_ID.clickCompleteYourProfileNavBtn,
				useBreadcrumb: true,
			},
			{
				code: 'profile',
				name: 'PROFILE',
				path: PATHS.care.profile,
				icon: PersonalCardSvg,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
				useBreadcrumb: true,
			},
			{
				code: 'edit-filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.edit_filter,
				icon: FindSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'referrer',
				name: 'REFERRER',
				icon: UserPlusSvg,
				path: PATHS.care.referrer,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'contact-us',
				name: 'CONTACT_US',
				icon: ContactUsSvg,
				path: PATHS.care.contact_us,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				useBanner: false,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'popular-cities',
				name: 'POPULAR_CITIES',
				path: PATHS.care.popular_cities,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
				showOnboardReminder: true,
				useBreadcrumb: true,
			},

			{
				code: 'popular-cities-detail',
				name: 'POPULAR_CITY_JOBS',
				path: PATHS.care.popular_cities_detail,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBreadcrumb: true,
			},

			{
				code: 'recommended-jobs',
				name: 'EXPLORE_MATCHES',
				path: PATHS.care.suggested_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
				showOnboardReminder: true,
				useBreadcrumb: true,
			},
			{
				code: 'urgent-needs',
				name: 'HOT_JOBS',
				path: PATHS.care.urgent_needs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
				showOnboardReminder: true,
				useBreadcrumb: true,
			},
			{
				code: 'trending-jobs',
				name: 'TRENDING_JOBS',
				path: PATHS.care.trending_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
				showOnboardReminder: true,
			},
			{
				code: 'last-viewed',
				name: 'LAST_VIEWED',
				useBreadcrumb: true,
				path: PATHS.care.last_viewed,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'available-perdiem-shift',
				name: 'AVAILABLE_PERDIEM_SHIFT',
				useBreadcrumb: true,
				path: PATHS.care.available_perdiem_shift,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'bookmark',
				name: 'BOOKMARKED_JOBS',
				path: PATHS.care.bookmark,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'settings',
				name: 'SETTINGS',
				path: PATHS.care.settings,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				icon: SettingsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useBanner: false,
				showDropdown: false,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'change-password',
				name: 'CHANGE_PASSWORD',
				icon: ChangePassSvg,
				path: PATHS.common.change_password,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				showDropdown: true,
				bannerUrl: PATHS.banners.third,
			},

			{
				code: 'skills-checklists',
				name: 'SKILLS_CHECKLISTS',
				path: PATHS.care.skills_checklists,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: true,
				bannerUrl: PATHS.banners.first,
				useBreadcrumb: true,
			},
			{
				code: 'jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: true,
				bannerUrl: PATHS.banners.first,
				useBreadcrumb: true,
			},
			{
				code: 'skills-checklists',
				name: 'SKILLS_CHECKLISTS',
				path: PATHS.care.skills_checklists,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: true,
				bannerUrl: PATHS.banners.first,
				useBreadcrumb: true,
			},
			{
				code: 'privacy-policy',
				name: 'PRIVACY_POLICY',
				path: PATHS.care.privacy_policy,
				icon: PrivacySvg,
				auth: false,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useBanner: false,
				useLayout: true,
			},

			{
				code: 'verify-credential',
				name: 'VERIFY_CREDENTIAL',
				path: PATHS.common.verify_credential,
				icon: 'privacy',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useLayout: false,
			},
			{
				code: 'email-unsubscribe',
				name: 'EMAIL_UNSUBSCRIBE',
				path: PATHS.care.email_unsubscribe,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.second,
			},
		]

		const publicMenu = [
			{
				code: 'authorization',
				name: 'AUTHORIZATION',
				path: PATHS.common.authorization,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useBanner: false,
			},
			{
				code: 'job-results',
				name: 'SEARCH_JOBS',
				path: PATHS.care.search,
				icon: FindSvg,
				iconFilled: FindFullSvg,
				auth: false,
				showAppBar: false,
				eventId: EVENT_CLICK_ID.clickSearchJobsNavBtn,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useBreadcrumb: true,
			},

			{
				code: 'privacy-policy',
				name: 'PRIVACY_POLICY',
				path: PATHS.care.privacy_policy,
				icon: 'privacy',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useLayout: false,
			},
			{
				code: 'email-unsubscribe',
				name: 'EMAIL_UNSUBSCRIBE',
				path: PATHS.care.email_unsubscribe,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
				bannerUrl: PATHS.banners.second,
			},
		].filter(Boolean)

		const common = [
			{
				code: 'authorization',
				name: 'AUTHORIZATION',
				path: PATHS.common.authorization,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useBanner: false,
			},
			{
				code: 'jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useBreadcrumb: true,
			},
			{
				code: 'verify-credential',
				name: 'VERIFY_CREDENTIAL',
				path: PATHS.care.verify_credential,
				icon: 'privacy',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useLayout: false,
			},
			{
				code: 'job-results',
				name: 'SEARCH_JOBS',
				path: PATHS.care.search,
				icon: FindSvg,
				iconFilled: FindFullSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: true,
				eventId: EVENT_CLICK_ID.clickMyJobsNavBtn,
				bannerUrl: PATHS.banners.third,
			},
			{
				code: 'SCREEN_SIGNUP',
				name: 'SIGNUP',
				path: PATHS.common.signup,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useBanner: false,
			},
			{
				code: 'signin',
				name: 'SIGNIN',
				path: PATHS.common.signin,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useBanner: false,
			},
			{
				code: 'forgot-password',
				name: 'RESET',
				path: PATHS.common.reset,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'change-password',
				name: 'REFER_TRAVELER',
				path: PATHS.common.change_password,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.third,
			},
			{
				code: 'errors',
				name: 'ERRORS',
				path: PATHS.common.errors,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
		].filter(Boolean)

		let result = null
		switch (type) {
			case FEATURE_TYPE.activated:
				result = activatedMenu
				break
			case FEATURE_TYPE.nonActivated:
				result = nonActivatedMenu
				break
			case FEATURE_TYPE.public:
				result = publicMenu
				break
			case FEATURE_TYPE.common:
				result = common
				break
			default:
				result = []
				break
		}
		return result
	}

	@action
	toogleProfileSidebar = async () => {
		this.showProfileSidebar = !this.showProfileSidebar
	}

	@action
	toggleOnboardReminder = async () => {
		this.showOnboardReminderCard = !this.showOnboardReminderCard
	}

	@action
	logout = async () => {
		this.showProfileSidebar = false

		eventBus.post(EVENTS.authStore.logout)
	}

	@action
	handleClickChangePassword = async () => {
		this.showProfileSidebar = false
		await routerStore.goPage(PATHS.common.change_password)
	}

	@event(EVENTS.careStore.updateHeader)
	async onUpdateHeader({ payload }) {
		this.customHeader = payload
	}

	@action
	resetWorkerBasicInfo = () => {
		this.workerBasicInfo = {}
	}

	@action
	updateWorkerBasicInfo = (payload) => {
		this.workerBasicInfo = { ...this.workerBasicInfo, ...payload }
	}
}

export const careStore = new CareStore()
